/* © Microsoft Corporation. All rights reserved. */

@font-face {
  font-family: 'CVSHealth';
  font-weight: 300;
  src: url('assets/CVSHealthSans_W_Rg.woff') format('woff');
}
@font-face {
  font-family: 'CVSHealth';
  font-weight: 400;
  src: url('assets/CVSHealthSans_W_Rg.woff') format('woff');
}

@font-face {
  font-family: 'CVSHealth';
  font-weight: 500;
  src: url('assets/CVSHealthSans_W_Rg.woff') format('woff');
}

@font-face {
  font-family: 'CVSHealth';
  font-weight: 600;
  src: url('assets/CVSHealthSans_W_Md.woff') format('woff');
}

@font-face {
  font-family: 'CVSHealth';
  font-weight: 700;
  src: url('assets/CVSHealthSans_W_Md.woff') format('woff');
}

@font-face {
  font-family: 'CVSHealth';
  font-weight: 800;
  src: url('assets/CVSHealthSans_W_Bd.woff') format('woff');
}
body,
html,
.Root {
  font-family: 'CVSHealth', -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen', 'Ubuntu', 'Cantarell', 'Fira Sans',
    'Droid Sans', 'Helvetica Neue', sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  margin: 0;
  padding: 0;
  width: 100%;
  height: 100%;
}

h1{
  color:#004D99;
}

.wrapper {
  height: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
}

.ui-provider {
  height: 0;
}

.thButton {
  width: 100%;
  display: inline-flex;
  align-items: center;
  justify-content: center;
  min-height: 44px;
  padding: 8px 16px;
  gap:8px;
  border-radius: 24px;
  border: 1px solid #004D99;
  color: white;
  background: #004D99;
  cursor: pointer;
  text-decoration: none;
  box-shadow: 0 4px 6px rgba(0, 0, 0, 0.1);
  margin-top:16px;
}


.thButton.half {
  width:40%;
  margin-right: 10%;
}

.thButton:hover {
  border-color: #004D99;
  color: #004D99;
  background: white;
}


  .thButton:active {
      border-color: #0066CC;
      background: white;
      color: #0066CC;
  }

  .thButton.secondary {
      border-color: #1A1A19;
      background: white;
      color: #004D99;
  }

      .thButton.secondary:hover {
          border-color: #1A1A19;
          color: white;
          background: #013F7C;
      }


      .thButton.secondary:active {
          border-color: #0066CC;
          background: #0066CC;
          color: white;
      }

      .ms-MessageBar-innerText {
          font-size: 16px;
      }

      .is-disabled{
        border-color: grey;
        background-color: white;
        color: grey;
      }

      .is-disabled:hover {
        border-color: grey;
        background-color: white;
        color: grey;
      }

      .ms-Spinner-label{
        color:black;
        font-weight:600;
        font-size: 14px;
      }

      .circle-43 {
        border-color: #CC0000 #FFFFFF00 #CC0000 #CC0000;
        width: 30px;
        height:30px;
        border-width: 2px;
        animation-timing-function: linear;
      }

      .ms-Spinner-circle.ms-Spinner--large{
        border-color: #CC0000 #FFFFFF00 #CC0000 #CC0000;
        width: 30px;
        height:30px;
        border-width: 2px;
      }

      .ms-Dropdown-title {
        border-color: 'lightgrey';
        border-width: 1px;
        border-radius: 6px;
      }

      .ms-Button--primary{
        border-radius: 24px;
      }

      .ms-Button--default{
        border-radius: 24px;
      }

      [aria-label="Mic"].ms-Button--default{
        border-radius: 0px;
      }

      [aria-label="Camera"].ms-Button--default{
        border-radius: 0px;
      }

      .ms-TextField-fieldGroup {
        border-radius: 6px;
        border-color: lightgrey;
      }

       [data-ui-id="lobbyScreenTitle"] {
        color:black;
        font-weight: 600;
      } 

      [data-ui-id="call-composite-start-call-button"]:hover {
        border-color: #004D99;
        color: #004D99;
        background: white;
      }
      
      
      [data-ui-id="call-composite-start-call-button"]:active {
            border-color: #0066CC;
            background: white;
            color: #0066CC;
        }


        [data-ui-id="left-call-page"] .ms-Button--primary:hover{
          border-color: #004D99;
          color: #004D99;
          background: white;
        }

        [data-ui-id="left-call-page"] .ms-Button--primary:active {
          border-color: #0066CC;
          background: white;
          color: #0066CC;
        }

        .ms-Drialog-main .ms-Button{
          width: 45%;
        }

        .ms-Dialog-main .ms-Button--primary:hover{
          border-color: #004D99;
          color: #004D99;
          background: white;
        }

        .ms-Dialog-main .ms-Button--primary:active {
          border-color: #0066CC;
          background: white;
          color: #0066CC;
      }

      .ms-Rating-button:hover .ms-RatingStar-front {
        color: #0066CC;
      }

      [data-ui-id="lobbyScreenTitle"]{
        display:block;
        padding: 30px;
        padding-top: 70px;
        background:#FFFFFFAA;
        border-radius: 24px;
        margin-top: -65px;
        min-width: 250px;
      }
