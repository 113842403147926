.tip-container{
    margin-top: 25px !important;
    font-weight: 600;
    position: relative;
    overflow: hidden;
    width: calc(100% + 80px);
    height: 70px;
    text-align: center;
}

.tip-container > div {
    position: absolute;
}

.active-tip{
    top: 0px;
    opacity: 1;
    transition: top 1s ease-in-out, opacity .3s ease-in-out .7s;
    
}

.next-tip{
    top: -80px;
    opacity: 0;
    
}

.inactive-tip{
    top: 80px;
    opacity: 0;
    transition: top 1s ease-in-out, opacity .3s ease-in-out;
}