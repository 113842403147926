/* Animation.css */
.fade-enter {
    transform: translateX(0%);
    opacity: 0;
}

.fade-enter-active {
    transform: translateX(0);
    opacity: 1;
    transition: transform 0.5s ease-in-out, opacity 0.5s ease-in-out;
}

.fade-leave {
    transform: translateX(0%);
    opacity: 0;
    transition: transform 0.5s ease-in-out, opacity 0.5s ease-in-out;
}